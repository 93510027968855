export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel je již přihlášen. Chcete-li pokračovat, obnovte stránku."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše heslo je kratší než 10 znaků. Chcete-li svůj účet chránit, změňte si heslo tak, aby bylo delší."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím na tlačítko Pokračovat přejdete na přihlašovací stránku."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K vaší e-mailové adrese není přiřazen žádný centrální účet. Požádejte prosím správce Central, aby vám vytvořil účet, abyste mohli pokračovat."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše e-mailová adresa nebyla ověřena přihlašovacím serverem. Obraťte se prosím na správce serveru."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central nemohl získat přístup k e-mailové adrese přidružené k vašemu účtu. Může to být způsobeno tím, že správce serveru něco špatně nakonfiguroval nebo že e-mailovou adresu pro váš účet nenastavil. Může to být také důsledek možností ochrany osobních údajů, které můžete zvolit během přihlašovacího procesu. V takovém případě to zkuste znovu a ujistěte se, že je váš e-mail sdílený."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při přihlašování se něco pokazilo. Kontaktujte prosím správce serveru."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávná e-mailová adresa nebo heslo."])}
        }
      },
      "de": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Benutzer ist bereits eingeloggt. Bitte die Seite aktualisieren um weiterzuarbeiten."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort ist kürzer als 10 Zeichen. Um Ihr Konto zu schützen, verlängerns Sie bitte Ihr Passwort."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf Weiter, um zur Anmeldeseite zu gelangen."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Ihrer E-Mail-Adresse ist kein Central-Konto verknüpft. Bitten Sie Ihren zentralen Administrator, ein Konto zu erstellen, damit Sie fortfahren können."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail-Adresse wurde von Ihrem Anmeldeserver nicht überprüft. Bitte wenden Sie sich an Ihren Serveradministrator."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central konnte nicht auf die mit Ihrem Konto verknüpfte E-Mail-Adresse zugreifen. Dies kann daran liegen, dass Ihr Serveradministrator etwas falsch konfiguriert hat oder keine E-Mail-Adresse für Ihr Konto festgelegt hat. Dies könnte auch auf Datenschutzoptionen zurückzuführen sein, die Sie während des Anmeldevorgangs auswählen können. Wenn ja, versuchen Sie es bitte erneut und stellen Sie sicher, dass Ihre E-Mail-Adresse geteilt wird."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Anmelden ist ein Fehler aufgetreten. Bitte wenden Sie sich an Ihren Serveradministrator."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche E-Mail-Adresse und/oder Passwort."])}
        }
      },
      "es": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un usuario ya ha iniciado sesión. Actualice la página para continuar."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña tiene menos de 10 caracteres. Para proteger su cuenta, cambie su contraseña para que sea más larga."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic en Continuar para pasar a la página de inicio de sesión."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ninguna cuenta Central asociada con su dirección de correo electrónico. Pídale a su administrador central que cree una cuenta para continuar."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su dirección de correo electrónico no ha sido verificada por su servidor de inicio de sesión. Comuníquese con el administrador de su servidor."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central no pudo acceder a la dirección de correo electrónico asociada con su cuenta. Esto podría deberse a que el administrador de su servidor haya configurado algo incorrectamente o no haya configurado una dirección de correo electrónico para su cuenta. También podría ser el resultado de las opciones de privacidad que puedes elegir durante el proceso de inicio de sesión. Si es así, inténtalo de nuevo y asegúrate de que tu correo electrónico esté compartido."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal durante el inicio de sesión. Comuníquese con el administrador de su servidor."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico y/o contraseña incorrecta."])}
        }
      },
      "fr": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utilisateur est déjà connecté. Merci de rafraîchir la page pour continuer."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe fait moins de 10 caractères. Pour protéger votre compte, merci de choisir un mot de passe plus long."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur Continuer pour accéder à la page de connexion."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de compte Central associé à votre adresse de courriel. Veuillez demander à votre administrateur de Central de vous créer un compte pour continuer."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse de courriel n'a pas été vérifiée par votre serveur de connexion. Veuillez contacter l'administrateur de votre serveur."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central n'a pas pu accéder à l'adresse de courriel associée à votre compte. Cela peut être dû au fait que l'administrateur de votre serveur a configuré quelque chose de manière incorrecte ou n'a pas défini d'adresse de courriel pour votre compte. Cela peut également être dû aux options de confidentialité que vous pouvez choisir durant la procédure de connexion. Si c'est le cas, veuillez réessayer et vous assurer que votre adresse de courriel est partagée."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème s'est produit lors de la connexion. Veuillez contacter l'administrateur de votre serveur."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de courriel et/ou mot de passe invalides."])}
        }
      },
      "id": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seorang pengguna sudah masuk. Mohon perbarui halaman untuk melanjutkan."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat email dan/atau kata sandi salah."])}
        }
      },
      "it": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utente ha già effettuato l'accesso. Aggiorna la pagina per continuare."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua password è lunga meno di 10 caratteri. Per proteggere il tuo account, cambia la password per renderla più lunga."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare clic su Continua per passare alla pagina di Login."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al suo indirizzo e-mail non è associato alcun account su Central. Chiedete all'amministratore di Central di creare un account per continuare."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il vostro indirizzo e-mail non è stato verificato dal server di accesso. Contattare l'amministratore del server."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central non è riuscito ad accedere all'indirizzo e-mail associato al vostro account. Ciò potrebbe essere dovuto al fatto che l'amministratore del server ha configurato qualcosa di errato o non ha impostato un indirizzo e-mail per l'account. Potrebbe anche essere il risultato delle opzioni di privacy che si possono scegliere durante il processo di login. In tal caso, riprovare e assicurarsi che il proprio indirizzo e-mail sia condiviso."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto durante l'accesso. Contattare l'amministratore del server."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail e/o password errati."])}
        }
      },
      "ja": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでにユーザーでログインされています。 続けるにはページを更新してください。"])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのパスワードは10文字以下です。アカウントを守るため、パスワードを長いものに変更してください。"])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスとパスワードの一方、または両方が違います。"])}
        }
      },
      "pt": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O usuário encontrasse logado atualmente. Por favor atualize a página para continuar."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua senha é menor que 10 caracteres. Para proteger sua conta, por favor crie uma senha mais longa."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique em Continuar para prosseguir para a página de login."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há nenhuma conta no Central associada ao seu endereço de e-mail. Peça ao seu administrador do Central para criar uma conta para você continuar."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu endereço de e-mail não foi verificado pelo seu servidor de login. Por favor, entre em contato com o administrador do seu servidor."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Central não conseguiu acessar o endereço de e-mail associado à sua conta. Isso pode ter ocorrido porque o administrador do servidor configurou algo incorretamente ou não definiu um endereço de e-mail para a sua conta. Também pode ser resultado de opções de privacidade que você pode escolher durante o processo de login. Nesse caso, tente novamente e certifique-se de que seu e-mail seja compartilhado."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo deu errado durante o login. Por favor, entre em contato com o administrador do seu servidor."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de email e/ou senha incorretos."])}
        }
      },
      "sw": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mtumiaji tayari ameingia. Tafadhali onyesha upya ukurasa ili kuendelea."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenosiri lako ni fupi kuliko vibambo 10. Ili kulinda akaunti yako, tafadhali badilisha nenosiri lako ili kuifanya iwe ndefu."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bofya Endelea ili kuendelea na ukurasa wa kuingia."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakuna akaunti ya Kati inayohusishwa na anwani yako ya barua pepe. Tafadhali muulize msimamizi wako Mkuu akufungulie akaunti ili uendelee."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwani yako ya barua pepe haijathibitishwa na seva yako ya kuingia. Tafadhali wasiliana na msimamizi wa seva yako."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central haikuweza kufikia anwani ya barua pepe inayohusishwa na akaunti yako. Hii inaweza kuwa kwa sababu msimamizi wa seva yako amesanidi kitu vibaya, au hajaweka anwani ya barua pepe kwa akaunti yako. Inaweza pia kuwa matokeo ya chaguzi za faragha ambazo unaweza kuchagua wakati wa mchakato wa kuingia. Ikiwa ndivyo, tafadhali jaribu tena na uhakikishe kuwa barua pepe yako inashirikiwa."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitilafu fulani imetokea wakati wa kuingia. Tafadhali wasiliana na msimamizi wa seva yako."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwani ya barua pepe na/au nenosiri si sahihi."])}
        }
      },
      "zh-Hant": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者已登入，重新載入頁面再繼續進行。"])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的密碼少於 10 個字元。為了保護您的帳戶，請變更密碼並增加密碼長度。"])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點選繼續進入登入頁面。"])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有與您的電子郵件地址關聯的 Central 帳戶。請要求您的 Central 管理員建立帳戶以便您繼續登入。"])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的登入伺服器尚未驗證您的電子郵件地址。請聯絡您的伺服器管理員。"])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central 無法存取與您的帳戶關聯的電子郵件地址。這可能是因為您的伺服器管理員配置不正確，或沒有為您的帳戶設定電子郵件地址。這也可能是您在登入過程中可以選擇的隱私選項的結果。如果是這樣，請重試並確保您的電子郵件已分享。"])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入期間出現問題。請聯絡您的伺服器管理員。"])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件地址和/或密碼不正確。"])}
        }
      }
    }
  })
}
